.App {
  text-align: center;
}

/**
*   Styles for Header
**/

.header-text {
    text-align: center;
}

.header-main-title {
    margin-top: 40px;
    margin-bottom: 0px;
    font-family: New Century Schoolbook;
    font-size: 1.8em;
}

.header-main-title__first-letter {
    font-size: 1.3em;
}

.header-main-text {
    margin-bottom: 20px;
    font-family: Helvetica, sans-serif;
    font-size: 1em;
    color: rgb(150, 150, 150);
}

.navbar-nav > li {
    margin-left: 20px;
    margin-right: 20px;
}

/**
*   Styles for Footer
**/

.footer {
    border-top-style: solid;
    border-top-width: 1px;
    border-color: rgba(0.0, 0.0, 0.0, 0.12);
    padding-top: 20px;
    padding-bottom: 10px;
}

.footer Button {
    border-radius: 50px;
    padding: 8px 28px 8px 28px;
    font-size: 14px;
    margin-top: 10px;
    margin-bottom: 15px;
}

.footer Button:hover {
    background-color: rgba(0.0, 0.0, 0.0, 0.2);
    border-color: rgba(0.0, 0.0, 0.0, 0.12);
}

/**
*   Styles for Home
**/

.header-top-image {
    object-fit: cover;
    object-position: center;
    animation-name: zoom-image;
    animation-duration: 60s;
    animation-iteration-count: infinite;
}

@keyframes zoom-image {
    0% { transform: scale(1.0); }
    50% { transform: scale(1.2); }
    100% { transform: scale(1.0); }
}

.image-top {
    position: relative;
    text-align: center;
    overflow: hidden;
}

.image-centered-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-family: "Arial Nova Cond Light";
    font-style: italic;
    font-weight: 400;
    font-size: 2em;
    z-index: 10;
}

.home-quote {
    font-family: Helvetica, sans-serif;
    font-weight: 900px;
    font-size: 1em;
    margin-top: 120px;
    margin-bottom: 120px;
}

.card-gallery {
    border-style: solid;
    border-width: 2px;
    border-color: rgba(0.0, 0.0, 0.0, 0.22);
    border-radius: 10px;
    overflow: hidden;
    margin-top: 50px;
    margin-bottom: 50px;
    transition: 0.5s;
}

.card-gallery:hover {
    transform: scale(1.03);
}

.gallery-text {
    text-align: start;
    position: relative;
    margin: 15px;
    height: 100%;
}

.text-galleries-title {
    font-family: Helvetica, sans-serif;
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 0px;
}

.text-galleries-quote {
    font-family: Helvetica, sans-serif;
    font-size: 16px;
    margin-top: 15px;
    margin-bottom: 0px;
}

.text-galleries-author {
    font-family: Helvetica, sans-serif;
    font-size: 14px;
    color: grey;
    margin-top: 5px;
    font-style: italic;
}

.card-phone {
    margin-top: 30px;
    margin-bottom: 30px;
    padding: 0px;
}

/**
* Styles for components
**/

.component-top {
    border-top-style: solid;
    border-top-width: 1px;
    border-color: rgba(0.0, 0.0, 0.0, 0.12);
    margin-top: 10px;
}

.text-title {
    font-family: Helvetica, sans-serif;
    font-weight: 500;
    font-size: 1.4em;
    color: grey;
    margin-top: 35px;
    margin-bottom: 25px;
}

.text-description {
    text-align: left;
    color: grey;
}

.form-label {
    margin-top: 15px;
    font-weight: 480;
    color: rgba(0.0, 0.0, 0.0, 0.4);
}

.button-submit {
    text-align: center;
    padding: 10px 40px 10px 40px;
    border-radius: 10px;
    border-color: rgba(0.0, 0.0, 0.0, 0.12);
    border-width: 3px;
    font-size: 0.8em;
    margin-bottom: 100px;
    margin-top: 20px;
}

.button-submit:hover {
    background-color: rgba(0.0, 0.0, 0.0, 0.2);
    border-color: rgba(0.0, 0.0, 0.0, 0.12);
}

/**
*   Styles for Gallery Carousel
**/

.gallery-title {
    text-align: center;
    margin-top: 10px;
    padding-top: 10px;
    margin-bottom: 0px;
    font-family: Helvetica, sans-serif;
    font-size: 1.5em;
    font-weight: 520;
    color: grey;
}

.gallery-country {
    text-align: center;
    margin-top: 0px;
    padding-top: 0px;
    margin-bottom: 20px;
    font-family: Helvetica, sans-serif;
    font-size: 1em;
    font-weight: 400;
    color: grey;
}

.gallery-background {
    position: absolute;
    width: 100%;
    height: 150px;
    background-color: rgb(200, 200, 200);
}

#gallery-main-div {
    margin-left: 10px;
}

#div-image {
    width: 100%;
    height: 100%;
    max-height: 450px;
    padding-left: 0px;
    padding-right: 10px;
    overflow: hidden;
}

#div-image-main {
    position: relative;
    overflow: hidden;
    background: rgba(150.0, 150.0, 150.0, 0.45);
}

#div-image img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    max-height: 400px;
    object-fit: cover;
    object-position: center;
    display: block;
    margin: auto;
    cursor: pointer;
}

.full {
    transition: all 0.3s ease-in;
}

.blur {
    filter: blur(5px);
    transform: scale(1.1);
    transition: visibility 0ms ease 400ms;
}

.gallery-view-photo-background {
    object-position: top;
    margin: auto;
}

.modal-image {
    width: 100%;
    height: 100%;
    object-position: top;
}

.img-center-crop {
    object-fit: cover;
    object-position: center;
    border-radius: 40px;
}

@media (min-width: 900px) {
    #div-image:hover img {
        transform: scale(1.1);
        filter: brightness(0.8);
    }
}

.column-div {
    width: 100%;
    height: auto !important;
    display: block;
}

.column-image-div {
    margin: 3px;
    position: relative;
    background: rgba(150.0, 150.0, 150.0, 0.45);
    overflow: hidden;
}

.column-image-div img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    cursor: pointer;
}

.avoid-column-break {
    -webkit-column-break-inside: avoid;
    page-break-inside: avoid;
    break-inside: avoid;
}

@media (min-width: 900px) {
    .column-image-div:hover img {
        transform: scale(1.05);
        filter: brightness(0.8);
    }
}

#photos-portrait {
    /* Prevent vertical gaps */
    line-height: 0;

    -webkit-column-gap:   0px;
    -moz-column-gap:      0px;
    column-gap:           0px;
}

@media (max-width: 4000px) {
    #photos-portrait {
        -moz-column-count:    5;
        -webkit-column-count: 5;
        column-count:         5;
    }
}
@media (max-width: 2500px) {
    #photos-portrait {
        -moz-column-count:    4;
        -webkit-column-count: 4;
        column-count:         4;
    }
}
@media (max-width: 1720px) {
    #photos-portrait {
        -moz-column-count:    3;
        -webkit-column-count: 3;
        column-count:         3;
    }
}
@media (max-width: 992px) {
    #photos-portrait {
        -moz-column-count:    2;
        -webkit-column-count: 2;
        column-count:         2;
    }
}
@media (max-width: 576px) {
    #photos-portrait {
        -moz-column-count:    1;
        -webkit-column-count: 1;
        column-count:         1;
    }
}
